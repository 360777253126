import React from 'react';
import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';
import icon from '../assets/images/common/down.svg';
import { useTranslation } from '../utils/contexts/Localization'

function Roadmap(props) {
    const { t } = useTranslation();
    return (
        <div className='inner-page'>
            {<PageTitle title={t("RoadMap")} />}

            <section className="tf-section roadmap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                                <div className="left">
                                    <div className="roadmap-box-style active">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("June 2024- Pre sale")}</h6>
                                            <p>{t("NVF Token pre-sale period where investors can acquire tokens at discounted rates.")}</p>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("December 2024 - Development")}</h6>
                                            <p>{t("Development phase where the NVF Token team works on building the platform and its features.")}</p>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("June 2025 - Launch")}</h6>
                                            <p>{t("Official launch of the NVF Token platform and ecosystem to the public.")}</p>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("December 2025 - Exchange Listing")}</h6>
                                            <p>{t("NVF Token is listed on major cryptocurrency exchanges for trading and liquidity.")}</p>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("June 2026 - Partnership Expansion")}</h6>
                                            <p>{t("NVF Token forms strategic partnerships with key players in the blockchain and finance industry.")}</p>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">{t("December 2026 - Community Growth")}</h6>
                                            <p>{t("Focus on community engagement and growth initiatives to expand NVF Token's user base.")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {<CTA />}
        </div>
    );
}

export default Roadmap;
