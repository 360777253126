import React from 'react';

import img from '../../assets/images/common/img_cta.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { useTranslation } from '../../utils/contexts/Localization'


function CTA(props) {
    const { t } = useTranslation();

    const [dataTitle] = useState(
        {
            title: 'Launch on Ethereum',
            subtitle: 'Full support in project incubation'
        }
    )

   // const immm = ;
    return (
        <section className="tf-section tf_CTA">

                <div className="container relative">
                    <div className="overlay">
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="tf-title left mt58" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    {t(dataTitle.title)}
                                </h2>
                                <p className="sub">{t(dataTitle.subtitle)}</p>
                                <div className="wrap-btn">
                                    <Link to="/Token" className="tf-button style3">
                                        Mint Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                      {/*   <div className="col-md-6">
                        <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
                            <img className="move4" src={"https://img.pandagame.space/img/"} alt="" />
                        </div>
                        </div> */}
                    </div>
                </div>
            </section>
    );
}

export default CTA;