import React, { useState,useEffect } from 'react'

import { Link } from 'react-router-dom';
import { useTranslation } from '../../../utils/contexts/Localization'


function CTA(props) {

   // 创建一个Date对象实例
let now = new Date();
// 获取当前时间的分钟数
let minutes = now.getMinutes();


const { t } = useTranslation()  


    return (
        <section className="tf-section tf_CTA">

                <div className="container relative">
                    <div className="overlay">
                    </div>
                    {/* <div className="row">
                        <div className="col-md-6">
                            <div className="tf-title left mt66" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                               {t("Blind box")}
                                </h2>
                                <p className="sub">{t("A box full of unknowns")}</p>
                                
                                <div className="wrap-btn">
                                <Link to="/project_v1" className="tf-button style3">
                                     {   t("Mint Now")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
                              <img className="move4" src={"https://img.pandagame.space/img/"+(minutes)+".png"} alt="" />
                        </div>
                        </div>
                    </div> */}
                </div>
            </section>
    );
}

export default CTA;