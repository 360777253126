import React from 'react';
import { useState } from 'react';

import PropTypes from 'prop-types';
import useTranslation from '../../utils/useTranslation'
Token2.propTypes = {
    data: PropTypes.array
};


function Token2(props) {
    const { t } = useTranslation();
    const {data} = props;

    const [dataTitle] = useState(
        {
            title: 'Statistics token',
        }
    )
    return (
        <section className="tf-section token">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title mb20">
                                {t(dataTitle.title)}
                            </h2>
                            <div className="total_token">
                                <h6>{t("Total Supply")}: <span>10,000,000,000</span></h6>
                                <h6>{t("Public sale")}:  <span>1,000,000,000</span></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-progress-box">
                            <div className="content-progress-box">
                                 
                                  <div   className="progress-bar" data-percentage="27.3%">
                                            <p className="progress-title-holder">
                                                <span className="progress-title">{t("Liquidity")}</span>
                                                <span className="progress-number-wrapper">
                                                    <span className="progress-number-mark">
                                                        <span className="percent"></span>
                                                    </span>
                                                </span>
                                            </p>
                                            <div className="progress-content-outter">
                                                <div className="progress-content"></div>
                                            </div>
                                   </div>
                                  

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-progress-box">
                            <div className="content-progress-box">
                          
                                        <div  className="progress-bar" data-percentage="27.3%">
                                            <p className="progress-title-holder">
                                                <span className="progress-title">{t("Private Sale")}</span>
                                                <span className="progress-number-wrapper">
                                                    <span className="progress-number-mark">
                                                        <span className="percent"></span>
                                                    </span>
                                                </span>
                                            </p>
                                            <div className="progress-content-outter">
                                                <div className="progress-content"></div>
                                            </div>
                                        </div>
                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Token2;