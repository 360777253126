import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../utils/contexts/Localization'


function CTA2(props) {
    const { t } = useTranslation();

    return (
        <section className="tf-section partner_CTA">
                <div className="overlay">
                    <img src={require ("../../assets/images/backgroup/bg_partner.jpg")} alt="" />
         
                </div>
                <div className="container ">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-12">
                            <div className="tf-title mb0 left" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title mb8">
                                {t("The simplest way to invest in memory blockchain and metaverse")}
                                </h2>
                                
                                <div className="wrap-btn">
                                    <Link to="/Token" className="tf-button style2">
                                       {t("Apply Now")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="image">
                                <img className="img_main" src={require ("../../assets/images/common/img_partner.png")} alt="" />
                                <img className="icon icon_1" src={require ("../../assets/images/common/icon_partner_1.png")} alt="" />
                                <img className="icon icon_2" src={require ("../../assets/images/common/icon_partner_2.png")} alt="" />
                                <img className="icon icon_3" src={require ("../../assets/images/common/icon_partner_3.png")} alt="" />
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
    );
}

export default CTA2;