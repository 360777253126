
const dataRoadmap = [
    {
        id: 1,
        time: 'June 16, 2024',
        text1: 'Start pre-sale',
        status: 'active'
    },
    {
        id: 2,
        time: 'December 16, 2024',
        text1: 'Development phase begins',
        status: ''
    },
    {
        id: 3,
        time: 'June 16, 2025',
        text1: 'Official launch of NVF Token platform',
        status: ''
    },
    {
        id: 4,
        time: 'December 16, 2025',
        text1: 'NVF Token listed on major exchanges',
        status: ''
    },
    {
        id: 5,
        time: 'June 16, 2026',
        text1: 'Strategic partnerships formed',
        status: ''
    },
    {
        id: 6,
        time: 'December 16, 2026',
        text1: 'Community engagement initiatives',
        status: ''
    }

]

export default dataRoadmap;