import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../utils/contexts/Localization'
import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';
import useToast from '../utils/useToast'

 


function Contact(props) {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast()


  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [title, setTitle] = useState('')
  const [conText, steContext] = useState()


  const sendEmail=()=>{

    //发送邮件

    const url = 'https://mail.33125.pw/';
    const params = new URLSearchParams({
      email: email,
      title: phone,
      phone: title,
      content: conText
    });

    fetch(`${url}?${params.toString()}`, {
      method: 'GET', // 或 'POST', 取决于API要求
    }) 
    
    .then(data => {
      console.log(data);
      toastSuccess('Send Successfully!')
    })
    .catch(error => {
      console.error('Error:', error);
      toastError('Send Fail!')
    });

  }
  return (
    <div className="inner-page">
      {<PageTitle title={t("Contact")} />}

      <section className="tf-section project-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                /*  action="contact/contact-process.php" */
                className="form-contact"
              >
                <div className="project-info-form">
                  <h6 className="title">{t("Leave a message")}</h6>
                  <div className="form-inner">
                    <fieldset>
                      <label>{t("Full name")}</label>
                      <input  onChange={(e)=>{setTitle(e.target.value)}}
                        type="text"
                        id="name"
                        placeholder={t("Enter the name of your project")}
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label>{t("Email address")}</label>
                      <input  onChange={(e)=>{setEmail(e.target.value)}}
                        type="email"
                        id="email"
                        placeholder={t("Your email")}
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label>{t("Phone")}</label>
                      <input  onChange={(e)=>{setPhone(e.target.value)}}
                        type="number"
                        id="phone"
                        placeholder={t("Your phone")}
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label htmlFor="message">{t("Message")}</label>
                      <textarea  onChange={(e)=>{steContext(e.target.value)}}
                        id="message"
                        placeholder={t("Tell us about the project you want to launch")}
                        rows="5"
                        tabIndex="4"
                        name="message"
                        className="message"
                        required
                      ></textarea>
                    </fieldset>
                  </div>
                </div>

                <div className="wrap-btn" >
                  <button type="submit" className="tf-button style1" onClick={()=>{

sendEmail()
                      }}>
                    {t("Send message")}
                  </button>
                </div>
                <div className="wrap-btn">

                  {t("Email:")}    svip00090@gmail.com

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {<CTA />}
    </div>
  );
}

Contact.propTypes = {
  data: PropTypes.array
};

export default Contact;
