const dataFaq = [
    {
        id: 1,
        title: 'What is a blockchain presale?',
        content: 'A blockchain presale refers to the early stage offering of a new cryptocurrency or token to investors before it is made available to the general public. This phase often targets specific investors or communities, offering them the opportunity to buy at a lower price.'
    }, 
    {
        id: 2,
        title: 'How do I participate in a blockchain presale?',
        content: 'To participate in a blockchain presale, you typically need to register on the project\'s website, complete any required KYC (Know Your Customer) procedures, and follow the specific instructions provided for purchasing the tokens during the presale phase.'
    },
    {
        id: 3,
        title: 'What are the risks of participating in a blockchain presale?',
        content: 'Participating in a blockchain presale carries risks such as the potential lack of liquidity, the chance that the project might not succeed, or the possibility of encountering scams. It\'s crucial to conduct thorough research and due diligence before investing.'
    },
    {
        id: 4,
        title: 'What benefits can I expect from participating in a blockchain presale?',
        content: 'Benefits of participating in a blockchain presale include the opportunity to purchase tokens at a lower price before public availability, early access to potential high-growth assets, and sometimes additional bonuses or incentives offered by the project.'
    },
    {
        id: 5,
        title: 'How can I assess the legitimacy of a blockchain presale?',
        content: 'To assess the legitimacy of a blockchain presale, investigate the project\'s team, its roadmap, the transparency of its communication, community feedback, and any available audits or endorsements from reputable entities within the blockchain industry.'
    },
   
]


export default dataFaq;