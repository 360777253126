 
import img2 from '../images/logo/unicrypt.svg'
import img3 from '../images/logo/pinksale.svg' 

const dataProject = [
  
  {
    id: 6,
    img: img2,
    title: 'Unicrypt',
    desc: 'UNCX Network (formerly UniCrypt) is a leading provider of decentralized finance services.',
    price_1: '125M',
    price_2: '500M',
    price_3: '$0',
    link:"https://app.uncx.network/amm/uni-v2/ilo/0x02388721790AbC88699107d5Df0cE37d3148fE88"
  },
  {
    id: 7,
    img: img3,
    title: 'Pinksale',
    desc: 'PinkSale is a completely decentralized pre-sale platform with a huge fan base.',
    price_1: '125M',
    price_2: '500M',
    price_3: '$0',
    link:"https://www.pinksale.finance/launchpad/ethereum/0x41D305bD4df84921C1855Cf99B80aa07029ab8A8"
  } 
]

export default dataProject
